'use client';
import {useState, useEffect} from 'react';
import Link from 'next/link';
import Image from 'next/image';
import {useDispatch, useSelector} from 'react-redux';
import {StyledContainer} from '../../../../app/styles';
import {
  StyledAsideCorporations,
  StyledColumn,
  StyledColumnContainer,
  StyledCommonSearches,
  StyledDescription,
  StyledFooter,
  StyledList,
  StyledTrustPilotImg,
} from './styles';
import {FooterColumn} from './typings';

import {
  loadSettingsOptions,
  loadUserSettings,
  saveUserSettings,
  switchUserCurrency,
} from '@/redux/actions/settingsActions';
import {selectUserSettings} from '@/redux/reducers/userSettingsReducer';

import ActionText from '@/atoms/ActionText/ActionText';

import ActionButton from '@/molecules/Buttons/Action/ActionButton';
import SocialIcons from '@/molecules/SocialIcons/SocialIcons';
import Modal from '@/molecules/Modal/Modal';
import UserSettings from '@/molecules/Fragments/Modal/UserSettings/UserSettings';

import useAuth from '@/hooks/use-auth/use-auth';

const commonLinks = [
  {
    name: 'Paintings for Sale',
    url: '/art/product_category-painting',
  },
  {
    name: 'Fine Art Photography',
    url: '/art/product_category-photography',
  },
  {
    name: 'Art Prints',
    url: '/art/product_category-printmaking',
  },
  {
    name: 'Drawings for Sale',
    url: '/art/product_category-drawing',
  },
  {
    name: 'Collage for Sale',
    url: '/art/product_category-collage',
  },
  {
    name: 'Sculpture',
    url: '/art/product_category-sculpture',
  },
  {
    name: 'Digital Art',
    url: '/art/product_category-digital-art',
  },
  {
    name: 'Nude Art',
    url: '/art/subject-nudes-erotic',
  },
  {
    name: 'Cityscapes',
    url: '/art/subject-architecture-cityscapes',
  },
  {
    name: 'Animal Drawings',
    url: '/art/product_category-drawing/subject-animals-birds',
  },
  {
    name: 'Seascapes',
    url: '/art/q-seascapes',
  },
  {
    name: 'Abstract Painting',
    url: '/art/product_category-painting/subject-abstract-conceptual',
  },
  {
    name: 'Flower Drawings',
    url: '/art/product_category-drawing/subject-flowers-plants',
  },
  {
    name: 'Portraits',
    url: '/art/subject-people-portraits',
  },
  {
    name: 'Horse Paintings',
    url: '/art/product_category-painting/q-horse',
  },
  {
    name: 'Street Art',
    url: '/art/style-urban-and-pop',
  },
  {
    name: 'Landscape Art',
    url: '/art/subject-landscapes-sea-sky',
  },
  {
    name: 'Still Life Art',
    url: '/art/subject-still-life',
  },
  {
    name: 'Buy Framed Art',
    url: '/art/framed_or_ready_to_hang-on',
  },
  {
    name: 'Buy Art Gifts',
    url: '/staff-picks-category/gift-guides',
  },
] as const;

const mobileLinks = [
  {
    name: 'About Artfinder',
    url: '/about',
  },
  {
    name: 'Your Account',
    url: '/u/settings',
  },
  {
    name: 'Delivery',
    url: '/help/shipping-and-delivery',
  },
  {
    name: 'Returns & Refunds',
    url: '/help/returns-and-refunds',
  },
  {
    name: 'Customer Support',
    url: '/help/help-center',
  },
  {
    name: 'Contact Us',
    url: '/contact-us',
  },
];

const Footer = () => {
  const {
    currencySettings: {
      url: {currency: forcedCurrency},
    },
    data: userSettings,
  } = useSelector(selectUserSettings);

  const auth = useAuth();
  const [showCommonSearches, setShowCommonSearches] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const footerColumns: Readonly<FooterColumn[]> = [
    {
      title: 'About Artfinder',
      links: [
        {
          name: 'About Us',
          url: '/about',
        },
        {
          name: 'Press',
          url: '/press',
        },
        {
          name: 'Affiliates',
          url: '/affiliates',
        },
        {
          name: 'Partners',
          url: '/partners',
        },
        {
          name: 'Artfinder Trade',
          url: '/trade',
        },
        {
          name: 'Blog',
          url: '/blog',
        },
        {
          name: 'Our Artists',
          url: '/our-artists',
        },
        {
          name: 'Artists A-Z',
          url: '/artists-az',
        },
        {
          name: 'Contact Us',
          url: '/contact-us',
        },
        {
          name: 'Common Searches',
          action: () => setShowCommonSearches(!showCommonSearches),
        },
      ],
    },
    {
      title: 'For Buyers',
      links: [
        {
          name: 'Buy a Gift Card',
          url: '/products/giftcard',
        },
        {
          name: 'Redeem a Gift Card',
          url: '/credits/balance',
        },
        {
          name: 'Personal Shopping',
          url: '/personal-shopping',
        },
        {
          name: 'Invite Friends',
          url: '/invite',
        },
        {
          name: 'Your Account Settings',
          url: '/u/settings',
        },
        {
          name: 'Commission an Artwork',
          url: '/commissions',
        },
        {
          name: 'Find a Gift',
          url: '/seasonal/perfect-gifts',
        },
        {
          name: 'Delivery',
          url: '/help/shipping-and-delivery',
        },
        {
          name: 'Returns & Refunds',
          url: '/help/returns-and-refunds',
        },
        {
          name: 'Customer Support',
          url: '/help/help-center',
        },
      ],
    },
    {
      title: 'For Artists',
      links: [
        {
          name: 'Why Sell on Artfinder?',
          url: '/help/seller-how-it-works',
        },
        {
          name: 'Terms and Conditions',
          url: '/help/terms-and-conditions',
        },
        {
          name: 'Seller Support',
          url: '/help/seller-help-center',
        },
        {
          name: 'Apply to Sell',
          url: '/sell',
        },
        {
          name: 'How to Sell Art Online',
          url: '/help/apply',
        },
      ],
    },
    {
      title: 'Artwork',
      optional: true,
      links: [
        {
          name: 'Paintings',
          url: '/art/product_category-painting',
        },
        {
          name: 'Fine Art Photography',
          url: '/art/product_category-photography',
        },
        {
          name: 'Art Prints',
          url: '/art/product_category-printmaking',
        },
        {
          name: 'Sculptures',
          url: '/art/product_category-sculpture',
        },
        {
          name: 'Drawings',
          url: '/art/product_category-drawing',
        },
        {
          name: 'Collages',
          url: '/art/product_category-collage',
        },
        {
          name: 'Illustrations',
          url: '/art/style-graphic-illustrative-and-typographic-illustrative',
        },
      ],
    },
  ];
  const dispatch = useDispatch();

  useEffect(() => {
    const onSettingsLoadSuccess = (data) => {
      const userCurrency = data.currency;
      if (forcedCurrency && !auth.isAuthenticated() && userCurrency !== forcedCurrency) {
        const payload = {
          currency: userSettings.currency,
          country: userSettings.country,
          display_units: userSettings.display_units,
          language: userSettings.language,
        };

        dispatch(saveUserSettings(payload));
      }
    };

    dispatch(loadSettingsOptions());
    dispatch(loadUserSettings('footer', [onSettingsLoadSuccess]));
  }, []);

  useEffect(() => {
    dispatch(switchUserCurrency(auth.isAuthenticated()));
  }, [auth]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <StyledFooter>
        <StyledContainer className="desktop-layout">
          <h2>Footer</h2>

          <StyledColumnContainer>
            {footerColumns.map((column, columnKey) => (
              <StyledColumn key={columnKey} $optional={column.optional}>
                <h5>{column.title}</h5>
                <StyledList>
                  {column.links.map((link, linkKey) => (
                    <li key={linkKey}>
                      {link.url ? (
                        <Link href={link.url}>{link.name}</Link>
                      ) : (
                        link.action && <ActionText onClick={link.action}>{link.name}</ActionText>
                      )}
                    </li>
                  ))}
                </StyledList>
              </StyledColumn>
            ))}

            <StyledColumn>
              <aside>
                <h5>Your regional settings</h5>

                <StyledDescription>
                  <dt>Country:</dt>
                  <dd>{userSettings.country_display}</dd>
                  <dt>Language:</dt>
                  <dd>{userSettings.language_display}</dd>
                  <dt>Currency:</dt>
                  <dd>
                    {userSettings.currency} ({userSettings.currency_symbol})
                  </dd>
                  <dt>Units:</dt>
                  <dd>{userSettings.display_units}</dd>
                </StyledDescription>

                <ActionButton onClick={openModal}>Change my settings</ActionButton>
              </aside>

              <StyledAsideCorporations>
                <h5>Certifications</h5>

                <div className="footer-b-corporation">
                  <a
                    target="_blank"
                    href="https://www.bcorporation.net/en-us/find-a-b-corp/company/artfinder/"
                    title="Certified B corporation"
                  >
                    <Image
                      src={
                        'https://d2m7ibezl7l5lt.cloudfront.net/img/v2/bcorp/bcorp_tagline_white.a1f6cb0552a7.svg'
                      }
                      width={160}
                      height={80.41}
                      alt={
                        'Artfinder is B corporation certified. This company meets the highest standards of social and environmental impact.'
                      }
                    />
                  </a>
                </div>

                <div className="footer-trustpilot">
                  <a
                    target="_blank"
                    href="https://uk.trustpilot.com/review/artfinder.com?utm_medium=trustboxes&amp;utm_source=MicroStar"
                    title="Trustpilot"
                  >
                    <StyledTrustPilotImg
                      src={
                        'https://d2m7ibezl7l5lt.cloudfront.net/img/trustpilot-artfinder.138b9043dcc0.svg'
                      }
                      alt={'Rated Excellent - five stars on TrustPilot'}
                      width={300}
                      height={19.13}
                    />
                  </a>
                </div>
              </StyledAsideCorporations>
            </StyledColumn>
          </StyledColumnContainer>

          {showCommonSearches && <CommonSearches />}

          <StyledColumnContainer>
            <small>
              © Artfinder 2024<Link href="/help/terms-of-use">Terms of Use</Link>
              <Link href="/help/privacy-policy">Privacy Policy</Link>
            </small>

            <SocialIcons size={22} />
          </StyledColumnContainer>
        </StyledContainer>

        <StyledContainer className="mobile-layout">
          <h2>Footer</h2>

          <SocialIcons size={22} />

          {mobileLinks.map((link, key) => (
            <Link key={key} href={link.url}>
              {link.name}
            </Link>
          ))}

          <aside>
            <h5>Your regional settings</h5>

            <StyledDescription>
              <dt>Country:</dt>
              <dd>{userSettings.country_display}</dd>
              <dt>Language:</dt>
              <dd>{userSettings.language_display}</dd>
              <dt>Currency:</dt>
              <dd>
                {userSettings.currency} ({userSettings.currency_symbol})
              </dd>
              <dt>Units:</dt>
              <dd>{userSettings.display_units}</dd>
            </StyledDescription>

            <ActionButton onClick={openModal}>Change my settings</ActionButton>
          </aside>

          <StyledAsideCorporations>
            <h5>Certifications</h5>

            <div className="footer-b-corporation">
              <a
                target="_blank"
                href="https://bcorporation.net/directory/artfinder"
                title="Certified B corporation"
              >
                <Image
                  src={
                    'https://d2m7ibezl7l5lt.cloudfront.net/img/v2/bcorp/bcorp_tagline_white.a1f6cb0552a7.svg'
                  }
                  width={160}
                  height={80.41}
                  alt={
                    'Artfinder is B corporation certified. This company meets the highest standards of social and environmental impact.'
                  }
                />
              </a>
            </div>

            <div className="footer-trustpilot">
              <a
                target="_blank"
                href="https://uk.trustpilot.com/review/artfinder.com?utm_medium=trustboxes&amp;utm_source=MicroStar"
                title="Trustpilot"
              >
                <StyledTrustPilotImg
                  src={
                    'https://d2m7ibezl7l5lt.cloudfront.net/img/trustpilot-artfinder.138b9043dcc0.svg'
                  }
                  alt={'Rated Excellent - five stars on TrustPilot'}
                  width={300}
                  height={19.13}
                />
              </a>
            </div>
          </StyledAsideCorporations>

          <small>
            © Artfinder 2024<Link href="/help/terms-of-use">Terms of Use</Link>
            <Link href="/help/privacy-policy">Privacy Policy</Link>
          </small>
        </StyledContainer>
      </StyledFooter>

      {showModal && (
        <Modal onClose={closeModal}>
          <UserSettings userSettings={userSettings} closeCallback={closeModal} />
        </Modal>
      )}
    </>
  );
};

const CommonSearches = () => {
  return (
    <StyledCommonSearches>
      {commonLinks.map((link, key) => (
        <li key={key}>
          <Link href={link.url}>{link.name}</Link>
        </li>
      ))}
    </StyledCommonSearches>
  );
};

export default Footer;
